@import '../../../../../components/commons/styles/typography.scss';

.expiredToken {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &__logo {
    position: absolute;
    top: 56px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    p, h3 {
      font-family: $fontType-primary;
      font-style: normal;
      font-weight: $fontWeight-regular;
      font-size: $fontSize-m2;
      line-height: 28px;
      text-align: center;
      color: #475067;
      margin: 0;
    }

    h3 {
      font-weight: $fontWeight-medium;
      margin: 24px 0 30px;
    }
  }
}
