.reset {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #f7f6f2;
  padding: 32px 26px 48px;
  margin: auto 0 0;
  width: 100%;

  &__modFullScreen {
    height: 100%;
    justify-content: center;
  }

  h3 {
    margin: 0 auto 12px 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3f3e3a;
  }

  &__description {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    color: #5f5f5f;
    margin: 0 0 24px;
    text-align: left;
  }

  &__passwordInput__modSuccess {
    border-color: #33cc99 !important;
  }

  &__errors {
    margin: 0 0 4px;
    padding: 0;
    width: 100%;
    list-style-type: none;

    &_error {
      color: #6f7485;
      font-weight: 400;

      &__modHasError {
        color: #f67574;
      }

      &__modSuccess {
        color: #33cc99;
      }
    }
  }

  &__checkIcon {
    margin: 0 auto;
  }

  &__content {
    font-size: 16px;
    line-height: 19px;
    color: #5f5f5f;
    margin: 16px 0 24px;
    text-align: center;

    &__modSuccess {
      margin: 16px auto 5px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #4aba98;
    }
  }

  &__redirect {
    font-size: 16px;
    line-height: 19px;
    margin: 20px auto 16px;
  }

  &__copy {
    margin-top: 24px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    a {
      color: #5c7beb;
    }
  }

  &__footer {
    margin: auto 0 0;
  }
}

@media screen and (min-width: 768px) {
  .reset {
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0px 12px 32px rgba(29, 35, 58, 0.1);
    height: fit-content;
    max-height: 554px;
    max-width: 504px;
    padding: 48px 72px 42px;
    border-radius: 10px;

    h3 {
      margin: 0 auto 12px;
    }
    &__description {
      display: none;
    }

    &__resetButton {
      margin: 20px 0 0;
    }
  }
}
