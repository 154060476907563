// Font Types
$fontType-primary: var(--rubik);
$fontType-titles: var(--noto-serif);

// Font Weights
$fontWeight-regular: 400;
$fontWeight-medium: 500;
$fontWeight-bold: 700;
$fontWeight-black: 900;

// Font Sizes
$fontSize-s1: 12px;
$fontSize-s2: 14px;
$fontSize-s3: 16px;
$fontSize-s4: 18px;
$fontSize-m1: 20px;
$fontSize-m2: 24px;
$fontSize-m3: 30px;
$fontSize-l1: 32px;
$fontSize-l2: 56px;
